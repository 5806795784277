import React from 'react'
import { Link } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import loadable from "@loadable/component"
import { SiteMetaConfig } from "gatsby-theme-0/src/config/config-data"
import { kebabCase } from "lodash"
const Header = loadable(() => import("gatsby-theme-0/src/components/header"))
const Footer = loadable(() => import("gatsby-theme-0/src/components/footer"))
import "./post.css"


const TagPosts = ({ pageContext, location }) => {

  const { tags, tagPostCounts } = pageContext
  
  return (
    <>
      <GatsbySeo
        title={`All Tags | ${SiteMetaConfig.site_name}`}
        description={SiteMetaConfig.site_description}
        canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
        openGraph={{
          type: "website",
          url: `${SiteMetaConfig.site_url}${location.pathname}`,
          title: `All Tags | ${SiteMetaConfig.site_name}`,
          description: SiteMetaConfig.site_description,
          images: [
            { url: SiteMetaConfig.site_image },
          ],
          site_name: SiteMetaConfig.site_name,
        }}
        twitter={{
          handle: `@${SiteMetaConfig.site_twitter_handle}`,
          site: `@${SiteMetaConfig.site_name}`,
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: SiteMetaConfig.site_facebook_app_id,
        }}
        htmlAttributes={{
          lang: SiteMetaConfig.site_language,
        }}
      />
      <Header />
      <div id="tagpage_1662986679855" className="Page_Container">
        <div id="tagpage_1662986681896" className="Heading_Text">All Tags</div>
        <hr />
        {tags.map(tag => (
          <Link key={tag} className="Custom_Badge" to={`/blog/tag/${kebabCase(tag)}-page-1`}>
            #{tag} <span id="tagpage_1662986750382" style={{color: "var(--Secondary)"}}>({tagPostCounts[tag]})</span>
          </Link>
        ))}
      </div>
      <Footer CustomBottomHeight="yes" />
    </>
  )
}

export default TagPosts